// import React, { useEffect, useState } from 'react';
// import Cookies from 'cookies-js';
// import '../css/login.css';
// import Navbar2 from './navbar2';
// import axios from '../axios';
// import { Typography, TextField } from '@material-ui/core';
// import VisibilityIcon from '@material-ui/icons/Visibility';
// import VisibilityOff from '@material-ui/icons/VisibilityOff';

// const Login = ()=>{

//     const [cred, setCred] = useState({
//         email: '',
//         password: '',
//         remember_me: false
//     });
    

    // const [visibledialog, setVisibleDialog] = useState("");
    // const [visiblepassword, setVisiblePassword] = useState(false);

//     // use this for sending data to back-end
//     const {email, password, remember_me} = cred;

//     useEffect(()=>{
//         if(email_ref.current?.value === ''){
//             document.getElementById("email-label").style.cssText = "top: 16px;";
//         }
//         else{
//             document.getElementById("email-label").style.cssText = "top: -10px;font-size: 14px;";
//         }
//         if(password === ''){
//             document.getElementById("password-label").style.cssText = "top: 16px";
//         }
//         else{
//             document.getElementById("password-label").style.cssText = "top: -10px;font-size: 14px;";
//         }
//     }, [email, password]);


    // const Send = async (e)=>{
    //     e.preventDefault();
    //     setVisibleDialog("");
        

    //     let re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    //     if(!email){
    //         // alert("Please enter credentials.");
    //         setVisibleDialog("Please enter credentials.");
    //     }
    //     else if(!re.test(email)){
    //         // alert("Please enter valid email address");
    //         setVisibleDialog("Please enter valid email address");
    //     }
    //     else if(!password){
    //         // alert("Please enter password.")
    //         setVisibleDialog("Please enter password.");
    //     }
    //     else{
    //         e.target.style.cssText = "cursor: not-allowed;padding-left: calc(48% - 12px);padding-right: calc(48% - 13px)";
    //     e.target.innerHTML = `Login<svg width="25px" height="25px"  xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid" style="background: none;">
    //     <circle cx="75" cy="50" fill="#fff" r="6.39718">
    //         <animate attributeName="r" values="4.8;4.8;8;4.8;4.8" times="0;0.1;0.2;0.3;1" dur="1s" repeatCount="indefinite" begin="-0.875s"></animate>
    //     </circle>
    //     <circle cx="67.678" cy="67.678" fill="#fff" r="4.8">
    //         <animate attributeName="r" values="4.8;4.8;8;4.8;4.8" times="0;0.1;0.2;0.3;1" dur="1s" repeatCount="indefinite" begin="-0.75s"></animate>
    //     </circle>
    //     <circle cx="50" cy="75" fill="#fff" r="4.8">
    //         <animate attributeName="r" values="4.8;4.8;8;4.8;4.8" times="0;0.1;0.2;0.3;1" dur="1s" repeatCount="indefinite" begin="-0.625s"></animate>
    //     </circle>
    //     <circle cx="32.322" cy="67.678" fill="#fff" r="4.8">
    //         <animate attributeName="r" values="4.8;4.8;8;4.8;4.8" times="0;0.1;0.2;0.3;1" dur="1s" repeatCount="indefinite" begin="-0.5s"></animate>
    //     </circle>
    //     <circle cx="25" cy="50" fill="#fff" r="4.8">
    //         <animate attributeName="r" values="4.8;4.8;8;4.8;4.8" times="0;0.1;0.2;0.3;1" dur="1s" repeatCount="indefinite" begin="-0.375s"></animate>
    //     </circle>
    //     <circle cx="32.322" cy="32.322" fill="#fff" r="4.80282">
    //         <animate attributeName="r" values="4.8;4.8;8;4.8;4.8" times="0;0.1;0.2;0.3;1" dur="1s" repeatCount="indefinite" begin="-0.25s"></animate>
    //     </circle>
    //     <circle cx="50" cy="25" fill="#fff" r="6.40282">
    //         <animate attributeName="r" values="4.8;4.8;8;4.8;4.8" times="0;0.1;0.2;0.3;1" dur="1s" repeatCount="indefinite" begin="-0.125s"></animate>
    //     </circle>
    //     <circle cx="67.678" cy="32.322" fill="#fff" r="7.99718">
    //         <animate attributeName="r" values="4.8;4.8;8;4.8;4.8" times="0;0.1;0.2;0.3;1" dur="1s" repeatCount="indefinite" begin="0s"></animate>
    //     </circle>
    // </svg>`;
    //     e.target.setAttribute('disabled', true);
    //     const defaultHeaders = {
    //         "Content-Type": "application/json"
    //       };
    //     // Send Data to backend
    //     const response = await axios.post('/auth/login', {
    //             "email": email,
    //             "password": password
    //         },
    //         {
    //             headers: defaultHeaders,
    //             withCredentials: true
    //         }
    //     );
    //     console.log("Response of auth/login API call : ");
    //     console.log(response);
    //     console.log("Response data status",response.data.status);

    //     if(response.data.status){
    //         e.target.innerHTML = `Login`;
    //         e.target.removeAttribute('disabled');
    //         e.target.style.cssText = "cursor: pointer;padding-left: 180px;padding-right: 180px";
    //         if(response.data.data.user_details.flag_customer === true){
    //            window.location.replace(process.env.REACT_APP_HOME_CUSTOMER_REDIRECT_URL);
    //         }
    //         else if(response.data.data.user_details.flag_customer === false){
    //             console.log("CB-Admin");
    //             window.location.replace(process.env.REACT_APP_CBADMIN_REDIRECT_URL);
    //         }
    //     }
    //     else{
    //         e.target.innerHTML = `Login`;
    //         e.target.removeAttribute('disabled');
    //         e.target.style.cssText = "cursor: pointer;padding-left: 180px;padding-right: 180px";
    //         setVisibleDialog("Invalid Credentials.");
    //     }}
    // }

//     return (
//         <>
//             <Navbar2 />
//             <div className="login" id="login">
//                 <div className="box">
//                     <h1>Login</h1>
//                     <form method="POST" className="form">
//                         <div className="email-psw">
//                             <input ref={email_ref} type="email" id="email" name='email' value={cred.email} onChange={(e)=>{
//                                 console.log(e);
//                                 setCred({...cred, email: e.target.value})
//                             }} />
//                             {/* <TextField autoFocus={true} autoComplete={true} onFocus ref={email_ref} type="email" id="email" name="email" value={cred.email} onChange={(e)=>{
//                                 setCred({...cred, email: e.target.value})
//                             }} /> */}
//                             <label id="email-label" for="email">Email</label>
//                         </div>
//                         <div className="email-psw">
//                             <input type={visiblepassword ? "text": "password"} name="password" id="password" value={cred.password} onChange={(e)=>{
//                                 setCred({...cred, password: e.target.value})
//                             }} />
                            // {visiblepassword ? <VisibilityOff onClick={()=>{
                            //     setVisiblePassword(!visiblepassword)
                            // }} style={{marginLeft: "-35px"}} /> : <VisibilityIcon onClick={()=>{
                            //     setVisiblePassword(!visiblepassword)
                            // }} style={{marginLeft: "-35px"}} />}
//                             <label id="password-label" for="password">Password</label>
//                         </div>
//                         <div className="other">
//                             <div className="left">
//                                 <input type="checkbox" id="remember-me" onClick={(e)=>{
//                                     if(e.target.checked === false){
//                                         setCred({...cred, remember_me: false});
//                                     }
//                                     else{
//                                         setCred({...cred, remember_me: true});
//                                     }
//                                 }} />
//                                 <label for="remember-me">Remember Me</label>
//                             </div>
//                             <div className="right">
//                                 <a href="/">Forgot Password?</a>
//                             </div>
//                         </div>
//                         <div className="submit">
//                             {
//                                 visibledialog !== "" && visibledialog.length > 0 &&
//                                 <Typography variant="h5" className="erro_message" >{visibledialog}</Typography>
//                             }
//                             <button type="submit" onClick={Send} className="submit-btn">Login</button>
//                         </div>
//                     </form>
//                     <p><a href={process.env.REACT_APP_TERMS_CONDITION_URL}>Terms &amp; Conditions</a>     |     <a href={process.env.REACT_APP_TERMS_CONDITION_URL}>Privacy Policy</a></p>
//                 </div>
//             </div>
//         </>
//     )
// };

// export default Login;


import React, { useState } from 'react';
import Navbar from '../components/navbar2';
import { Box, Typography, FormControl, Button, Checkbox, TextField } from '@mui/material';
import '../css/login.css';
import {Link,useHistory} from 'react-router-dom';
import axios from '../axios';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { useLocation } from 'react-router-dom';

const Login = ()=>{

    const [cred, setCred] = useState({
        email: "",
        password: ""
    });
    const [visibleerror, setVisibleError] = useState(false);
    const [errormsg, setErrorMsg] = useState(null);
    const emailref = React.createRef(null);
    const [visiblepassword, setVisiblePassword] = useState(false);
    const history = useHistory();
    const location = useLocation();
    const {email, password} = cred;

    const Send = async (e)=>{
        e.preventDefault();
        setVisibleError(false);
        setErrorMsg("");

        let re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if(!email){
            setVisibleError(true);
            setErrorMsg("Please enter credentials.");
        }
        else if(!re.test(email)){
            setVisibleError(true);
            setErrorMsg("Please enter valid email address");
        }
        else if(!password){
            setVisibleError(true);
            setErrorMsg("Please enter password.");
        }
        else{
            e.target.style.cssText = "cursor: not-allowed;padding-left: calc(48% - 12px);padding-right: calc(48% - 13px)";
        e.target.innerHTML = `Login<svg width="25px" height="25px"  xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid" style="background: none;">
        <circle cx="75" cy="50" fill="#fff" r="6.39718">
            <animate attributeName="r" values="4.8;4.8;8;4.8;4.8" times="0;0.1;0.2;0.3;1" dur="1s" repeatCount="indefinite" begin="-0.875s"></animate>
        </circle>
        <circle cx="67.678" cy="67.678" fill="#fff" r="4.8">
            <animate attributeName="r" values="4.8;4.8;8;4.8;4.8" times="0;0.1;0.2;0.3;1" dur="1s" repeatCount="indefinite" begin="-0.75s"></animate>
        </circle>
        <circle cx="50" cy="75" fill="#fff" r="4.8">
            <animate attributeName="r" values="4.8;4.8;8;4.8;4.8" times="0;0.1;0.2;0.3;1" dur="1s" repeatCount="indefinite" begin="-0.625s"></animate>
        </circle>
        <circle cx="32.322" cy="67.678" fill="#fff" r="4.8">
            <animate attributeName="r" values="4.8;4.8;8;4.8;4.8" times="0;0.1;0.2;0.3;1" dur="1s" repeatCount="indefinite" begin="-0.5s"></animate>
        </circle>
        <circle cx="25" cy="50" fill="#fff" r="4.8">
            <animate attributeName="r" values="4.8;4.8;8;4.8;4.8" times="0;0.1;0.2;0.3;1" dur="1s" repeatCount="indefinite" begin="-0.375s"></animate>
        </circle>
        <circle cx="32.322" cy="32.322" fill="#fff" r="4.80282">
            <animate attributeName="r" values="4.8;4.8;8;4.8;4.8" times="0;0.1;0.2;0.3;1" dur="1s" repeatCount="indefinite" begin="-0.25s"></animate>
        </circle>
        <circle cx="50" cy="25" fill="#fff" r="6.40282">
            <animate attributeName="r" values="4.8;4.8;8;4.8;4.8" times="0;0.1;0.2;0.3;1" dur="1s" repeatCount="indefinite" begin="-0.125s"></animate>
        </circle>
        <circle cx="67.678" cy="32.322" fill="#fff" r="7.99718">
            <animate attributeName="r" values="4.8;4.8;8;4.8;4.8" times="0;0.1;0.2;0.3;1" dur="1s" repeatCount="indefinite" begin="0s"></animate>
        </circle>
    </svg>`;
        e.target.setAttribute('disabled', true);
        const defaultHeaders = {
            "Content-Type": "application/json"
          };
        // Send Data to backend
        const response = await axios.post('/auth/login', {
                "email": email,
                "password": password
            },
            {
                headers: defaultHeaders,
                withCredentials: true
            }
        );
        console.log("Response of auth/login API call : ");
        console.log(response);
        console.log("Response data status",response.data.status);

        if(response.data.status){
            e.target.innerHTML = `Login`;
            e.target.removeAttribute('disabled');
            e.target.style.cssText = "cursor: pointer;padding-left: 180px;padding-right: 180px";
            if(response.data.data.user_details.flag_customer === true){
               window.location.replace(process.env.REACT_APP_HOME_CUSTOMER_REDIRECT_URL);
            }
            else if(response.data.data.user_details.flag_customer === false){
                console.log("CB-Admin");
                window.location.replace(process.env.REACT_APP_CBADMIN_REDIRECT_URL);
            }
        }
        else{
            e.target.innerHTML = `Login`;
            e.target.removeAttribute('disabled');
            e.target.style.cssText = "cursor: pointer;padding-left: 180px;padding-right: 180px";
            setVisibleError(true);
            setErrorMsg("Invalid Credentials.");
        }}
    }

    return (
        <>
            <Navbar />
            <Box className="login" id="login">
                <Box className="box">
                    <Typography variant="h1">Login</Typography>

                    {/* {location?.state?.data == "" ? <span>{location?.state?.data}</span>:null} */}
                    
                    <FormControl className="form">
                        <Box className="email-psw">
                            {/* <InputLabel htmlFor="email" id="email-label">Email</InputLabel> */}
                            <TextField label="Email Address" variant="standard" type="email" id="email" name="email" value={email} onChange={(e)=>setCred({...cred, email: e.target.value})} />
                        </Box>
                        <Box className="email-psw">
                            {/* <InputLabel htmlFor="password" id="password-label">Password</InputLabel> */}
                            <TextField ref={emailref} label="Password" type="password" variant="standard" id="password" name="password" value={password} onChange={(e)=>setCred({...cred, password: e.target.value})} />
                            {visiblepassword ? <VisibilityOff   onClick={()=>{
                                setVisiblePassword(!visiblepassword)
                                emailref.current.children[1].childNodes[0].type = "password";
                            }} style={{marginLeft: "-35px", marginTop: '15px', zIndex: 99}} /> : <VisibilityIcon onClick={()=>{
                                setVisiblePassword(!visiblepassword)
                                emailref.current.children[1].childNodes[0].type = "text";
                            }} style={{marginLeft: "-35px", marginTop: '15px', zIndex: 99}} />}
                        </Box>
                        <Box className="other" style={{margin: '10px 0'}}>
                            <Box className="left" style={{display: 'flex', flexDirection: 'row'}}>
                                <Checkbox id="remember-me" style={{width: '20px', height: '20px', marginRight: '5px'}}  />
                                <Typography variant="label" htmlFor="remember-me">Remember Me</Typography>
                            </Box>
                            <Box className="right">
                                <Link to="/forgot-password">Forgot Password</Link>
                            </Box>
                        </Box>
                        <Box className="submit">
                            {
                               visibleerror === true && errormsg !== null &&  <Typography variant="h5" className="error_message">{errormsg}</Typography>
                            }
                            <Button onClick={Send} style={{textTransform: 'capitalize !important', fontWeight: 600}} className="submit-btn" >Login</Button>
                        </Box>
                    </FormControl>
                    <Typography style={{margin: '10px 0'}}><Link href={process.env.REACT_APP_TERMS_CONDITION_URL}>Terms &amp; Conditions</Link>     |     <Link href={process.env.REACT_APP_TERMS_CONDITION_URL}>Privacy Policy</Link></Typography>
                </Box>
            </Box>
        </>
    )
};

export default Login;