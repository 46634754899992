import axios from '../axios';

export async function get_user_info(data, onSuccess, onError){
	try{
		const res = await axios.get("/user/me", {
			headers: {
				"Content-Type": "application/json"
			},
			withCredentials: true
		});
		onSuccess && onSuccess(res);
	}
	catch(err){
		onError && onError(err);
	}
}