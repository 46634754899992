import React, { useState} from 'react';
import Navbar from '../components/navbar2';
import { Box, Typography, FormControl, Button, TextField } from '@mui/material';
import '../css/resetPassword.css';
import {Link,useHistory } from 'react-router-dom';
 import axios from '../axios';
import "../css/forgotPassword.css"
import OTPInput, { ResendOTP } from "otp-input-react";
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';


const ResetPassword = ()=>{
    const [visiblepassword, setVisiblePassword] = useState(false);
    const [visibleRepassword, setVisibleRePassword] = useState(false);
    const [passwordstatus,setPasswordstatus] = useState("");
    const history = useHistory();
    const [ rePassword,setRePassword] = useState("")
    const [checkPassword,setCheckPassword] = useState("")
   const [ password,setPassword] = useState("")
   const [OTP, setOTP] = useState("");
   const emailref = React.createRef(null);
   const rePasswordref = React.createRef(null);


//    const renderButton = (buttonProps) => {
//     return (
//       <button {...buttonProps}>
//         {buttonProps.remainingTime !== 0 ? `Please wait for ${buttonProps.remainingTime} sec` : "Resend"}
//       </button>
//     );
//   };
//   const renderTime = () => React.Fragment; 

   const passwordReset = async()=>{
            if(password === rePassword){
                console.log("OTP",OTP)
                    console.log("password",password)
                const response =await axios.put('/auth/reset-password', {
                    
                    "authorization_code": OTP,
                    "new_password": password,
                    // headers: {
                    //     "Content-Type": "application/json; charset=utf-8"
                    // },
                    // withCredentials: true
                  })
                  if(response.data.status == true){
                    console.log("status is true")
                    console.log("res.data.data",response)
                    setPasswordstatus("your password has been changed sucessfully") 

                    setTimeout(()=>{
                        history.push({pathname: "/",state :{data:passwordstatus}})
                        }, 2000);
                     
                
                     
                  }
                  else{
                    console.log("status is false")
                    console.log("res.data.data",response.data)
                    setPasswordstatus(response.data.error.message)
                   }
                // console.log("true")
             }
             else{
                setCheckPassword("please Enter same password")
             }

            }

    return (
        <>
            <Navbar />
            <Box className="login" id="login">
                <Box className="box">
                    <Typography variant="h1">Reset Password</Typography>
                    <FormControl className="form">
                        <Box className='email_text'>We have sent you the OTP in your given Email address.</Box>
                            <OTPInput
                            value={OTP}
                            onChange={setOTP}
                            autoFocus
                            OTPLength={6}
                            otpType="number"
                            disabled={false}
                            secure
                            
                            />
                            {/* {console.log("otp",OTP)} */}
                            <ResendOTP onResendClick={() => console.log("Resend clicked")} />
                            {/* <ResendOTP renderButton={renderButton} renderTime={renderTime} />; */}
  
                            {OTP.toString().length === 6? 
                            <>
                            <Box className="email-psw">
                            {/* <InputLabel htmlFor="password" id="password-label">Password</InputLabel> */}
                            <TextField 
                            ref={emailref}
                             label="Password" 
                             type="password" 
                             variant="standard" 
                             id="password" 
                             name="password"
                             value={password} 
                            onChange={(e)=>
                            {
                                setCheckPassword("");
                                setPassword(e.target.value)
                            }
                            } />
                            {visiblepassword ? <VisibilityOff   onClick={()=>{
                                setVisiblePassword(!visiblepassword)
                                emailref.current.children[1].childNodes[0].type = "password";
                            }} style={{marginLeft: "-35px", marginTop: '15px', zIndex: 99}} /> : <VisibilityIcon onClick={()=>{
                                setVisiblePassword(!visiblepassword)
                                emailref.current.children[1].childNodes[0].type = "text";
                            }} style={{marginLeft: "-35px", marginTop: '15px', zIndex: 99}} />}
                            </Box>


                            <Box className="email-psw">
                          
                            <TextField 
                            ref={rePasswordref} 
                            label="Re-Password" 
                            type="password" 
                            variant="standard" 
                            id="Re_password" 
                            name="Re_password" 
                            value={rePassword} 
                            onChange={(e)=>
                            {
                                setCheckPassword("");
                                setRePassword(e.target.value);
                            }
                            } />
                            {visibleRepassword ? <VisibilityOff   onClick={()=>{
                                setVisibleRePassword(!visibleRepassword)
                                rePasswordref.current.children[1].childNodes[0].type = "password";
                            }} style={{marginLeft: "-35px", marginTop: '15px', zIndex: 99}} /> : <VisibilityIcon onClick={()=>{
                                setVisibleRePassword(!visibleRepassword)
                                rePasswordref.current.children[1].childNodes[0].type = "text";
                            }} style={{marginLeft: "-35px", marginTop: '15px', zIndex: 99}} />}
                            </Box></>
                            :
                            <>
                            <Box className="email-psw">
                            {/* <InputLabel htmlFor="password" id="password-label">Password</InputLabel> */}
                            <TextField disabled 
                            ref={emailref} 
                            label="Password" 
                            type="password" 
                            variant="standard" 
                            id="password" 
                            name="password" 
                            value={password} 
                            onChange={(e)=>
                            {
                                setCheckPassword("");
                                setPassword(e.target.value);
                            }
                            } />
                            {visiblepassword ? <VisibilityOff  disabled onClick={()=>{
                                setVisiblePassword(!visiblepassword)
                                emailref.current.children[1].childNodes[0].type = "password";
                            }} style={{marginLeft: "-35px", marginTop: '15px', zIndex: 99}} /> : <VisibilityIcon disabled onClick={()=>{
                                setVisiblePassword(!visiblepassword)
                                emailref.current.children[1].childNodes[0].type = "text";
                            }} style={{marginLeft: "-35px", marginTop: '15px', zIndex: 99}} />}
                            </Box>


                            <Box   className="email-psw">
                          
                            <TextField disabled ref={rePasswordref} label="Re-Password" type="password" variant="standard" id="Re_password" name="Re_password" value={rePassword} onChange={(e)=>
                            {
                                setCheckPassword("");
                                setRePassword(e.target.value);
                            }
                            }/>
                            {visibleRepassword ? <VisibilityOff  disabled onClick={()=>{
                                setVisibleRePassword(!visibleRepassword)
                                rePasswordref.current.children[1].childNodes[0].type = "password";
                            }} style={{marginLeft: "-35px", marginTop: '15px', zIndex: 99}} /> : <VisibilityIcon disabled onClick={()=>{
                                setVisibleRePassword(!visibleRepassword)
                                rePasswordref.current.children[1].childNodes[0].type = "text";
                            }} style={{marginLeft: "-35px", marginTop: '15px', zIndex: 99}} />}
                            </Box></>}
                            <span>{checkPassword}</span>
                            <span>{passwordstatus}</span>
                        <Box className="submit">
                            
                            <Button onClick={passwordReset}  style={{ textTransform:"none !importanat" ,fontWeight: 600}} className="submit-btn">Reset</Button>
                        </Box>
                        
                    </FormControl>
                    <Typography style={{margin: '10px 0'}}><Link href={process.env.REACT_APP_TERMS_CONDITION_URL}>Terms &amp; Conditions</Link>     |     <Link href={process.env.REACT_APP_TERMS_CONDITION_URL}>Privacy Policy</Link></Typography>
                </Box>
            </Box>
        </>
    )
};

export default  ResetPassword;