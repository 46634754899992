import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../css/navbar2.css';

const Navbar2 = ()=>{
    return (
        <>
            <nav className="navbar navbar-expand-lg navbar-light fixed-top" id="nav">
                <div className="container-lg">
                    <a className="navbar-brand" href={process.env.REACT_APP_TERMS_CONDITION_URL}>
                        <img src="https://storage.googleapis.com/complaincebrain-public/compliance-brain-Website/logo-colorful.png" alt="Compliance Brain Logo"/>
                    </a>
                </div>
            </nav>
        </>
    )
};

export default Navbar2;
