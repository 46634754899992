import React from 'react';
import ReactDOM from 'react-dom';
import App from './app';
import './index.css';
import {BrowserRouter} from 'react-router-dom';
// import dotEnv from 'dotenv';
// dotEnv.config({path: ".env"});
console.log("Printing ENV", process.env.APICALL_LOCALHOST);

ReactDOM.render(
  <BrowserRouter>
    <App/>
  </BrowserRouter>,
  document.getElementById("root")
)