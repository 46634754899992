import React, { useState} from 'react';
import Navbar from '../components/navbar2';
import { Box, Typography, FormControl, Button, TextField } from '@mui/material';
import '../css/login.css';
import {Link,useHistory } from 'react-router-dom';
import axios from '../axios';
import "../css/forgotPassword.css"


const ForgotPassword = ()=>{
    const history = useHistory();
   const [email,setEmail]= useState("");
  
  const [message, setMessage] = useState("");
  const [err,setErr] = useState("")

    const validateEmail =(e)=>{
        setEmail(e.target.value);
        setMessage("");
        setErr("")
    }
    const submitEmail =async (e)=>{
        
        const response =await axios.put('/auth/forgot-password', {
                "email": email,
                headers: {
                        "Content-Type": "application/json"
                    },
                    withCredentials: true
                });
                console.log("printing responce of ForgotPassword in forgotPassword file", response)
                if(response.data.status == true){
                   // debugger
                    console.log("status is true")
                    console.log("res.data.data-----------------",response)
                    history.push("/reset-password")
                    
                     
                  }
                  else{
                    console.log("status is false")
                    setErr("We have No Record of that Email Address. ")
                    console.log("res.data.data",response)
                   }    

      const  pattern=/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

        if (pattern.test(email))
        {
            return true
        }
        else{
          setMessage('You have entered an invalid email address!');
        }
    }

    return (
        <>
            <Navbar />
            <Box className="login" id="login">
                <Box className="box">
                    <Typography variant="h1">Forgot Password</Typography>
                    <FormControl className="form">
                        <Box className='email_text'>Please enter your email address to receive a varification code </Box>
                        <Box className="email-psw">
                            <TextField label="Email Address" variant="standard"   type="email" id="email" name="email" value={email} onChange={validateEmail}
                          />
                        </Box>

                       {message !== "" ? <span style={{color:"red",justifyContent:"center",display:"flex"}}>{message}</span> : <span style={{color:"red",justifyContent:"center",display:"flex"}}>{err}</span>}
                        
                       

                        <Box className="submit">
                            {/* {
                               visibleerror === true && errormsg !== null &&  <Typography variant="h5" className="error_message">{errormsg}</Typography>
                            } */}
                            <Button  onClick={submitEmail} style={{ textTransform:"none !importanat" ,fontWeight: 600}} className="submit-btn">Submit</Button>
                        </Box>
                        <Box className='text'>I remembered my password<span> . </span> <a onClick={()=>{history.push("/")}}>  Login</a> </Box>
                    </FormControl>
                    <Typography style={{margin: '10px 0'}}><Link href={process.env.REACT_APP_TERMS_CONDITION_URL}>Terms &amp; Conditions</Link> | <Link href={process.env.REACT_APP_TERMS_CONDITION_URL}>Privacy Policy</Link></Typography>
                </Box>
            </Box>
        </>
    )
};

export default ForgotPassword;