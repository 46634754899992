import React, {useEffect, useState} from 'react';
import './app.css';
import {Switch, Route} from 'react-router-dom';
import Login from './components/login';
import {get_user_info} from './services/user';
import ForgotPassword from './components/forgotPassword';
import ResetPassword from './components/resetPassword';

const App = ()=>{
    const [authenticated, setAuthenticated] = useState(false);

    useEffect(async ()=>{
        get_user_info({}, (res)=>{
            if(res.data.status){
                setAuthenticated(true);
                if(res.data.data.flag_customer)
                    window.location.href = process.env.REACT_APP_HOME_CUSTOMER_REDIRECT_URL;
                else
                    window.location.href = process.env.REACT_APP_CBADMIN_REDIRECT_URL;
            }
        }, (err)=>{
            console.log(err);
        })
    }, [])

    return (
        authenticated ? null : (<>
            <Switch>
                <Route path="/" exact={true} component={Login}><Login /></Route>
                <Route path="/forgot-password"><ForgotPassword /></Route>
                <Route path="/reset-password"><ResetPassword /></Route>
            </Switch>
        </>)
    )
};

export default App;
